import React, { FC } from "react";

import { graphql } from "gatsby";
import BlogsMain from "../components/BlogUiComponents/BlogsMain/BlogsMain";
import Layout from "../components/Layout/Layout";
import { UseTagsFilter } from "../components/hooks/UseTagsFilter";

type Props = {
  pageContext: any;
  data: any;
  location: any;
};

const TagsNews: FC<Props> = ({ pageContext, data, location }) => {
  const { blogs, newsBlogsPaginationNumber, currentPage, tag } = pageContext;
  const selectedTags = UseTagsFilter("news", blogs, data.allTags.nodes);

  let categoryItem = data.allSanityTag.nodes[0].title;

  let filteredArray: any = [];
  const checkPath = (data: any) => {
    data.newsBlogTags.edges.forEach((x: any) => {
      x.node.tags.forEach((y: any) => {
        if (y.title === categoryItem) {
          filteredArray.push(x);
        }
        return;
      });
      return;
    });
  };

  checkPath(data);

  return (
    <Layout location={"/blog"}>
      <>
        <BlogsMain
          basePathForUrl={"/news"}
          basePathForPaginations={`/news/${tag}`}
          blogName={"/news"}
          data={filteredArray}
          numberPages={newsBlogsPaginationNumber}
          currentPage={currentPage}
          tags={selectedTags}
        />
      </>
    </Layout>
  );
};

export default TagsNews;

export const query = graphql`
  query ($tag: String, $skip: Int!, $limit: Int!) {
    newsBlogTags: allSanityBlog(
      limit: $limit
      skip: $skip
      filter: {
        blogType: { elemMatch: { title: { eq: "news" } } },
        tags: { elemMatch: { title: { ne: "DEUTSCH" } } }
      }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawBody
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          publishedAt
          body {
            children {
              text
            }
          }
          tags {
            title
            _id
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
       allTags: allSanityTag{
      nodes {
        title
        _id
      }
    }
  allSanityTag(filter: { title: { eq: $tag } }) {
      nodes {
        title
        _id
      }
    }
}
`;
